const Cookies = require("js-cookie");

const cartItemId = (cart_item) => {
  return [cart_item.id, cart_item.subaccount].filter((x) => x).join("-");
};

var storage = (function () {
  var uid = new Date();
  var result;
  try {
    localStorage.setItem(uid, uid);
    result = localStorage.getItem(uid) == uid;
    localStorage.removeItem(uid);
    return result && localStorage;
  } catch (exception) {}
})();

const ALLOWED_DATA_TYPES = {
  id: ["string", "number"],
  amount_cents: ["number"],
  name: ["string"],
  subaccount: ["string"],
  subaccountName: ["string"],
  img: ["string"],
  marketingCode: ["string"],
};

function filterParams(params) {
  // filter items with invalid data types
  return Object.keys(params).reduce((acc, key) => {
    if (key === "amount_cents") {
      if (isNaN(parseInt(params[key]))) {
        throw "amount_cents required param missing or not a number";
      }
      acc[key] = parseInt(params[key]);
    } else if (
      params[key] === null ||
      params[key] === undefined ||
      params[key] == "undefined"
    ) {
      // do nothing
    } else if (!ALLOWED_DATA_TYPES[key]) {
      acc[key] = params[key];
    } else if (ALLOWED_DATA_TYPES[key].includes(typeof params[key])) {
      acc[key] = params[key];
    }

    return acc;
  }, {});
}

module.exports = class {
  constructor(domain, storage_engine = storage) {
    this.domain = domain;
    this.store = storage_engine;
  }

  add(params) {
    const item = filterParams(params);

    if (!item.id) {
      throw "id required param missing or not a string or number";
    }

    if (!item.name) {
      throw "name required param missing or not a string";
    }

    if (!item.amount_cents) {
      throw "amount_cents required param missing or not a number";
    }

    const id = item.id;

    let cartContents = this.getItems().filter((i) => {
      return cartItemId(i) != cartItemId(item);
    }); // prevents duplicates

    cartContents.push(item);

    this.store.setItem("merlin_cart", JSON.stringify(cartContents));
    Cookies.set("merlin_cartCount", cartContents.length, { expires: 7 }); // Store cartCount in a cookie for 7 days
  }

  remove(id) {
    let cartContents = this.getItems();

    // return all elements where id does NOT match
    cartContents = cartContents.filter((item) => item.id != id);

    this.store.setItem("merlin_cart", JSON.stringify(cartContents));
    Cookies.set("merlin_cartCount", cartContents.length, { expires: 7 }); // Update cartCount in a cookie
  }

  empty() {
    this.store.removeItem("merlin_cart");
    Cookies.set("merlin_cartCount", 0, { expires: 7 }); // Set cartCount to 0 when the cart is emptied
  }

  getItems() {
    let cartContents = this.store.getItem("merlin_cart");

    if (cartContents) {
      return JSON.parse(cartContents);
    }

    return [];
  }

  getNumberOfItems() {
    let cartContents = this.getItems();

    return cartContents.length;
  }

  storeUtmParams() {
    if (this.store) {
      const utm_params = window.location.search.substring(1);

      const filtered_params = utm_params
        .split("&")
        .filter((param) => {
          return param.startsWith("utm_") || param.startsWith("sc");
        })
        .reduce((acc, param) => {
          const [key, value] = param.split("=");
          acc[key] = value;
          return acc;
        }, {});

      const existing = this.store.getItem("merlin_cart_utm");

      if (existing) {
        const existing_params = JSON.parse(existing);
        const merged_params = { ...existing_params, ...filtered_params };
        this.store.setItem("merlin_cart_utm", JSON.stringify(merged_params));
      } else {
        this.store.setItem("merlin_cart_utm", JSON.stringify(filtered_params));
      }
    }
  }
};
